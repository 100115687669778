<template>
  <div class="caseVue">
    <header class="header">
      <h1>荣誉资质</h1>
    </header>
    <section class="ziji">
      <div class="cardSelect">
        <ul>
          <li :class="active == 0 ? 'active' : ''" @click="active = 0">资质</li>
          <li :class="active == 1 ? 'active' : ''" @click="active = 1">荣誉</li>
        </ul>
      </div>
      <b-container fluid="xl">
        <b-row :class="active == 0 ? 'show' : 'hide'">
          <b-col md="4" v-for="(item, i) in qualification" :key="i">
            <div class="img_content">
              <img :src="item.imagePath ? $imgUrl + item.imagePath : ''" alt="" />
            </div>
            <p>{{ item.title }}</p>
          </b-col>
        </b-row>
        <b-row :class="active == 1 ? 'show' : 'hide'">
          <b-col md="4" v-for="(item, i) in honor" :key="i">
            <div class="img_content">
              <img :src="item.imagePath ? $imgUrl + item.imagePath : ''" alt="" />
            </div>
            <p>{{ item.title }}</p>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  components: {},
  data() {
    return {
      active: 0,
      qualification: [],
      honor: [],
    };
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  async mounted() {
    let _this = this;
    let ziji = await this.$http.get("/qualification");
    if (ziji.status == 200) {
      console.log("资质", ziji);
      _this.qualification = ziji.data.qualification;
      _this.honor = ziji.data.honor;
      // _this.services = ziji.data;
    }

    // 动画重启
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      var wow = new WOW({
        live: false,
      });
      wow.init();
    });
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.caseVue .header {
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>